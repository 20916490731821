import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import rickImage1 from './images/rick1.png';
import rickImage2 from './images/rick2.png';
import rickImage3 from './images/rick3.png';
import rickImage4 from './images/rick4.png';
import microphoneImageLeft from './images/microphone1.png';
import microphoneImageRight from './images/microphone2.png';
import microphoneImageLeft2 from './images/microphone3.png';
import microphoneImageRight2 from './images/microphone4.png';

function App() {
  const videoUrl = 'https://shattereddisk.github.io/rickroll/rickroll.mp4';

  const [muted, setMuted] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.setItem('darkMode', !isDarkMode);
  };

  useEffect(() => {
    const userPrefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDarkMode(userPrefersDarkMode);
  }, []);

  const darkModeClass = isDarkMode ? 'dark-mode' : '';

  const toggleMute = () => {
    setMuted(false);
  };

  useEffect(() => {
    document.addEventListener('click', toggleMute);

    return () => {
      document.removeEventListener('click', toggleMute);
    };
  }, []);

  useEffect(() => {
    const microphoneLeft = document.getElementById("microphone-left");
    const microphoneRight = document.getElementById("microphone-right");
    const microphoneLeft2 = document.getElementById("microphone-left2");
    const microphoneRight2 = document.getElementById("microphone-right2");

    const handleScroll = () => {
      const scrollValue = window.scrollY;
      microphoneLeft.style.transform = `translateY(-${scrollValue * 0.25}px)`;
      microphoneRight.style.transform = `translateY(-${scrollValue * 0.2}px)`;
      microphoneLeft2.style.transform = `translateY(-${scrollValue * 0.1}px)`;
      microphoneRight2.style.transform = `translateY(-${scrollValue * 0.3}px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`App ${darkModeClass}`}>
      <div className="parallax-container">
        <div className="parallax-content">
          <div className="banner">
            <div className="toggle-switch">
              <input
                type="checkbox"
                className="toggle-input"
                id="dark-mode-toggle"
                checked={isDarkMode}
                onChange={toggleDarkMode}
              />
              <label htmlFor="dark-mode-toggle" className="toggle-label">
                <FontAwesomeIcon icon={faMoon} className="toggle-icon" />
                <div className="toggle-slider"></div>
                <FontAwesomeIcon icon={faSun} className="toggle-icon" />
              </label>
            </div>
            <div class="text-container">
              Garver Summit 2023
            </div>
          </div>
          <div className="content">
            <div className="above-video-text">IT is never gonna give you up!</div>
            <div className="video-container">
              <ReactPlayer
                playsinline={true}
                url={videoUrl}
                playing={true}
                className="video-player"
                controls={false}
                muted={muted}
                loop={true}
                width="100%"
              />
            </div>
            <div className="education-container">
              <div className="education">
                <p>We're no strangers to phishing, you know the rules, and so do I</p>
              </div>
              <div className="image-container">
                <img src={rickImage1} alt="Rick" />
              </div>
              <div className="education">
                <p>A QR code is what they'll be thinking of, sent via email or a paper from a guy.</p>
              </div>
              <div className="image-container">
                <img src={rickImage2} alt="Rick" />
              </div>
              <div className="education">
                <p>I just want to tell you what we're seeing, gotta make you understand</p>
              </div>
              <div className="image-container">
                <img src={rickImage3} alt="Rick" />
              </div>
              <div className="education">
                <p>Never gonna click that link, never gonna scan that code</p>
              </div>
              <div className="image-container">
                <img src={rickImage4} alt="Rick" />
              </div>
              <div className="education">
                <p>Never gonna fall for their tricks, and I'll report them</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="microphone-container">
        <img src={microphoneImageLeft} alt="Left Microphone" id="microphone-left" className="left-microphone" />
        <img src={microphoneImageRight} alt="Right Microphone" id="microphone-right" className="right-microphone" />
        <img src={microphoneImageLeft2} alt="Left Microphone2" id="microphone-left2" className="left-microphone2" />
        <img src={microphoneImageRight2} alt="Right Microphone2" id="microphone-right2" className="right-microphone2" />
      </div>
    </div>
  );
}

export default App;
